import MainLayout from '@/components/main-layout';
import SEO from '@/components/seo';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

type ONasProps = {
  data: any;
};

const ONas: React.FC<ONasProps> = ({
  data: { contentfulAboutUs: aboutUsData },
}) => (
  <>
    <SEO
      title="O nás"
      description="Sme architektonicko - projekčná kancelária, ktorá sa venuje projekčnej činnosti v oblasti stavebníctva pozemných stavieb."
      image="/zelenak.jpg"
    />
    <MainLayout>
      <div className="flex flex-col lg:flex-row items-center lg:items-start text-white container m-auto md:px-20 px-4 py-20 text-center lg:text-justify">
        <div className="lg:hidden">
          <h1 className="text-2xl font-semibold">{aboutUsData.name}</h1>
          <div className="italic">{aboutUsData.position}</div>
        </div>
        <GatsbyImage
          image={getImage(aboutUsData.photo)}
          alt="Jaroslav Zelenak photo"
          className="flex-shrink-0 w-80 my-10 lg:my-0"
        />
        <div className="lg:ml-16">
          <h1 className="text-2xl font-semibold hidden lg:block">
            {aboutUsData.name}
          </h1>
          <div className="italic mb-10 hidden lg:block">
            {aboutUsData.position}
          </div>
          <p>{aboutUsData.text.text}</p>
        </div>
      </div>
    </MainLayout>
  </>
);

export const query = graphql`
  {
    contentfulAboutUs {
      photo {
        gatsbyImageData(placeholder: BLURRED)
      }
      position
      text {
        text
      }
      name
    }
  }
`;

export default ONas;
